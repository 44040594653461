<template>
  <div id="stone-details">
    <!--    <van-nav-bar-->
    <!--      title="用料标准"-->
    <!--      left-arrow-->
    <!--      @click-left="goBack"-->
    <!--    />-->
    <div class="head" @click="changeType">
      <div>切换石种</div>
      <div class="head-name">
        {{ list.basItemName }}（{{ list.type == 0 ? '主材' : '辅材' }}）
        <img :src="arrowIcon" alt="">
      </div>
    </div>
    <div class="tab-bar"
         v-if="(list.acceptItemPlans && list.acceptItemPlans.length > 0 )|| (list.refuseItemPlans && list.refuseItemPlans.length > 0)">
      <div class="tab-bar-item" v-for="(item,index) in ['达标','非达标']" :key="index"
           :class="{'tab-bar-item-active' : currentIndex == index}" @click="tabChange(index)">
        {{ item }}
      </div>
    </div>
    <!-- 内容 -->
    <div v-show="currentIndex == 0">
      <div class="content" v-if="list.acceptItemPlans && list.acceptItemPlans.length > 0">
        <div class="group" v-for="(item,index) in list.acceptItemPlans" :key="index">
          <div class="group-title">{{ item.itemPlanName }}</div>
          <div class="color-img-title" v-if="item.spacePlanImgs.length > 0">彩平图</div>
          <div class="group-img-wrapper" v-for="(x,y) in item.spacePlanImgs" :key="y">
            <!-- <img :src="x.planImg" @click="showImage(item, y, 1)"> -->
            <!-- <van-image width="100%" :src="x.planImg" @click="showImage(item, y, 1)"></van-image> -->
            <van-image width="100%" :src="x.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.spacePlanImgs, y, 1)"></van-image>
          </div>
          <div class="color-img-title" v-if="item.slabCutPlanImgs.length > 0">大板切割图</div>
          <div v-for="(val,i) in item.slabCutPlanImgs" :key="i">
            <!-- <img class="color-img" :src="val.planImg" @click="showImage(item, i, 2)"> -->
            <van-image width="100%" :src="val.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.slabCutPlanImgs, i, 2)"/>
          </div>
          <div class="color-img-title" v-if="item.slabPlanImgs.length > 0">大板图</div>
          <div v-for="(val,i) in item.slabPlanImgs" :key="i">
            <!-- <img class="color-img" :src="val.planImg" @click="showImage(item, i, 2)"> -->
            <van-image width="100%" :src="val.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.slabPlanImgs, i, 3)"/>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="list.acceptItemPlans && list.acceptItemPlans.length == 0">
        <img :src="noData" alt="">
        暂无数据
      </div>
    </div>

    <!-- 内容 -->
    <div v-show="currentIndex == 1">
      <div class="content" v-if="list.refuseItemPlans && list.refuseItemPlans.length > 0">
        <div class="group" v-for="(item,index) in list.refuseItemPlans" :key="index">
          <div class="group-title">{{ item.itemPlanName }}</div>
          <div class="color-img-title" v-if="item.spacePlanImgs.length > 0">彩平图</div>
          <div class="group-img-wrapper" v-for="(x,y) in item.spacePlanImgs" :key="y">
            <!-- <img :src="x.planImg" @click="showImage(item, y, 1)"> -->
            <!-- <van-image width="100%" :src="x.planImg" @click="showImage(item, y, 1)"></van-image> -->
            <van-image width="100%;" :src="x.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.spacePlanImgs, y, 1)"></van-image>
          </div>
          <div class="color-img-title" v-if="item.slabCutPlanImgs.length > 0">大板切割图</div>
          <div v-for="(val,i) in item.slabCutPlanImgs" :key="i">
            <!-- <img class="color-img" :src="val.planImg" @click="showImage(item, i, 2)"> -->
            <van-image width="100%" :src="val.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.slabCutPlanImgs, i, 2)"/>
          </div>
          <div class="color-img-title" v-if="item.slabPlanImgs.length > 0">大板图</div>
          <div v-for="(val,i) in item.slabPlanImgs" :key="i">
            <!-- <img class="color-img" :src="val.planImg" @click="showImage(item, i, 2)"> -->
            <van-image width="100%" :src="val.planImg + '?x-oss-process=image/resize,w_592'"
                       @click="showImage(item.slabPlanImgs, i, 3)"/>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="list.refuseItemPlans && list.refuseItemPlans.length == 0">
        <img :src="noData" alt="">
        暂无数据
      </div>
    </div>

    <!--    <div class="ls-loading" v-show="isLoading">-->
    <!--      <img class="loading" :src="iconPath" alt="Loading...">-->
    <!--    </div>-->

    <!--    <div class="dialog" v-show="showChange">-->
    <!--      <quote-card @chose="chose" :isShowInput="false" :dataList="typeList" :isShowArrow="true" :isInside="isInside"/>-->
    <!--    </div>-->

    <!-- <div class="no-data" v-if="contentList.length == 0">
      <img :src="noData" alt="">
      暂无数据
    </div> -->
    <van-loading v-if="isLoading"/>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive, toRefs} from "vue";
import {getEngineeringItem} from '@/api/quitedPrice'
import {ImagePreview} from 'vant';

export default {
  name: "stoneDetail",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      arrowIcon: require('../../assets/quotedPrice/icon_left.png'),
      // iconPath: require('../../../../static/image/Wedges-3s-200px.svg'),
      noData: require("@/assets/quotedPrice/none@2x.png"),
      currentIndex: 0,
      list: [],
      contentList: [],
      isLoading: false,
      query: {},
      typeList: [],
      showChange: false,
      isInside: true,
      endInquiryId: '',
    })
    // 切换石种
    const changeType = () => {
      console.log(555, state.endInquiryId)
      router.push({
        path: '/stoneList',
        query: {
          endInquiryId: state.endInquiryId,
        }
      })
      // if(browser.versions.ios) {
      //   LS.openPage({
      //     pageName: `quotation-submit.html`,
      //     query:{
      //       type: 'changeStone',
      //       title: '用料清单'
      //     },
      //   });
      // } else {
      //   this.showChange = true
      //   this.setNavBar('用料清单')
      // }

    }
    // 切换tab
    const tabChange = (val) => {
      if (state.currentIndex != val) {
        state.currentIndex = val
      }
    }
    const getStoneDetail = () => {
      state.isLoading = true
      getEngineeringItem(state.query).then(res => {
        state.list = res.data
        if (state.list.acceptItemPlans.length > 0) {
          // this.contentList = this.list.acceptItemPlans
          state.currentIndex = 0
        } else {
          // this.contentList = this.list.refuseItemPlans
          state.currentIndex = 1
        }
        state.isLoading = false
      })
    }
    // 查看图片
    const showImage = (imgList, index, type) => {
      console.log(imgList, index, type)
      const newImgList = []
      imgList.filter((item) => {
        return newImgList.push(item.planImg)
      })
      ImagePreview({
        images: newImgList,
        startPosition: index,
        closeable: true,
      })
    }
    const goBack = () => {
      window.history.go(-1);
    }
    onMounted(() => {
      window.document.title = '用料标准';
      state.endInquiryId = route.query.endInquiryId
      state.query.engineeringId = route.query.engineeringId
      state.query.engineeringItemId = route.query.engineeringItemId
      // console.log(state.query)
      getStoneDetail()
    })
    return {
      goBack,
      changeType,
      tabChange,
      showImage,
      getStoneDetail,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
html, body {
  width: 100%;
  height: 100%;
}

#stone-details {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  //position: relative;
}

.van-loading {
  position: fixed !important;
  font-size: 0;
  vertical-align: middle;
  top: 50% !important;
  left: 50% !important;
  //z-index: 100;
}

.head {
  width: 100%;
  height: 0.56rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.16rem;
  box-sizing: border-box;
  font-size: 0.17rem;
  color: #333333;
  border-bottom: 1px solid #F7F7F7;

  .head-name {
    display: flex;
    width: fit-content;
    white-space: nowrap;
    align-items: center;

    img {
      height: 0.17rem;
      width: auto;
      margin-left: 0.04rem;
    }
  }
}

.tab-bar {
  width: 100%;
  padding: 0.12rem 0.16rem;
  box-sizing: border-box;
  display: flex;

  .tab-bar-item {
    width: 0.74rem;
    height: 0.32rem;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #3967A2;
    color: #3967A2;
    line-height: 0.32rem;
    text-align: center;
    font-size: 0.14rem;
    margin-right: 0.12rem;
  }

  .tab-bar-item-active {
    background: #3967A2;
    border: 1px solid #3967A2;
    color: #FFFFFF;
  }
}

.content {
  padding-bottom: 0.2rem;
}

.group {
  width: 100%;
  padding: 0 0.16rem 0.16rem;
  border-bottom: 0.02rem dashed #3967A2;
  box-sizing: border-box;
  margin-bottom: 0.16rem;

  &:last-child {
    border: none;
  }

  .group-title {
    font-size: 0.15rem;
    font-weight: bold;
    color: #333333;
  }

  .group-img-wrapper {
    width: 100%;
    padding: 0.08rem;
    box-sizing: border-box;
    border: 1px solid #3967A2;
    border-radius: 0.03rem;
    margin-top: 0.12rem;
    display: flex;

    .van-image {
      width: 100%;
    }

    /deep/ .van-image__error {
      position: static;
      min-height: 100px !important;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .color-img-title {
    width: 100%;
    text-align: center;
    margin: 0.1rem 0;
    font-size: 0.12rem;
    font-weight: bold;
    color: #333333;
  }

  .color-img {
    width: 100%;
    height: auto;
  }
}

.no-data {
  width: 100%;
  //margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-weight: 400;
  color: #969799;

  img {
    margin-top: 20vh;
    width: 66%;
    height: auto;
  }
}
</style>
