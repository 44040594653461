import instance from "../../utils/request";

/**
 * 报价书列表
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const selectEndInquiry = (params) => {
  return instance({
    url: "/h5/liH5EndInquiry/selectEndInquiry",
    method: 'post',
    data: params,
  })
}
/**
 * 报价书详情
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const getEngineeringInfo = (params) => {
  return instance({
    url: "/h5/liH5EndInquiry/getEngineering",
    method: 'post',
    data: params,
  })
}
/**
 * 报价书详情 无需登录
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const getEngineeringInfoNoToken = (params) => {
  return instance({
    url: "/h5/liH5Bulletin/getEngineering",
    method: 'post',
    data: params,
  })
}
/**
 * 查看报价书石种详情
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const getEngineeringItem = (params) => {
  return instance({
    url: "/h5/liH5EndInquiry/getEngineeringItem",
    method: 'post',
    data: params,
  })
}

/**
 * 报价信息
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const getQuotedPriceInfo = (params) => {
  return instance({
    url: "/h5/liH5EndInquiry/getQuotedPriceInfo",
    method: 'post',
    data: params,
  })
}

/**
 * 单文件上传
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const uploadFile = (params) => {
  return instance({
    url: "/liAttachment/uploadFile",
    method: 'post',
    data: params,
  })
}

/**
 * 报价
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export const quotedPrice = (params) => {
  return instance({
    url: "h5/liH5EndInquiry/quotedPrice",
    method: 'post',
    data: params,
  })
}

export default {
  getEngineeringItem,
  selectEndInquiry,
  getEngineeringInfo,
  getEngineeringInfoNoToken,
  getQuotedPriceInfo,
  uploadFile
};
